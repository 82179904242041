import { type Analytics, getAnalytics, logEvent } from 'firebase/analytics'
import type {
  Clinic,
  ClinicOrderKey,
  ClinicListElement,
  SavedClinic,
} from '~/typeEntities/stores/Clinic'
import type {
  MenuCardType,
  MenuListElement,
  SavedMenu,
} from '~/typeEntities/stores/Menu'

// GA4の推奨イベントとそのパラメータ一覧
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=ja&client_type=gtag#select_content

// firebase analyticsのinitが終わる前に呼ばれるとダメなので、遅延評価する
let analyticsInstance: Analytics | null = null
const analytics = () => {
  if (analyticsInstance === null) {
    analyticsInstance = getAnalytics()
  }
  return analyticsInstance
}

const log = (eventName: string, eventParams?: any) => {
  logEvent(analytics(), eventName, eventParams)
}

const formatClinic = (clinic: Clinic | ClinicListElement) => {
  return {
    item_id: clinic.id,
    item_name: clinic.name,
    // 以下、カスタムパラメータ（最大27個までにすること）
    section: clinic.section?.name,
    prefecture: clinic.section?.prefecture?.name,
    reports_count: clinic.reportsCount,
    menus_count: clinic.menusCount,
    total_report_rating: clinic.totalReportRating,
    doctor_assignments_count: clinic.doctors.length,
    articles_count: clinic.articles.length,
    store_photos_count: clinic.photos.length,
  }
}
const formatSavedClinic = (clinic: SavedClinic) => {
  return {
    item_id: clinic.id,
    item_name: clinic.name,
    // 以下、カスタムパラメータ（最大27個までにすること）
    reports_count: clinic.reportsCount,
    total_report_rating: clinic.totalReportRating,
    store_photos_count: clinic.photos.length,
  }
}

const formatSavedMenu = (menu: MenuCardType | SavedMenu) => {
  return {
    item_id: menu.id,
    item_name: menu.name,
    // 以下、カスタムパラメータ（最大27個までにすること）
    treatment_ids: menu.treatments.map((treatment) => treatment.id),
    lowest_price: menu.lowestPrice,
  }
}

const formatClinicForInList = (clinic: ClinicListElement, index: number) => {
  const formattedClinic: any = formatClinic(clinic)
  formattedClinic.index = index
  return formattedClinic
}

const formatSpecialMenu = (clinic: Clinic, menu: MenuListElement) => {
  return {
    item_id: menu.id,
    item_name: menu.name,
    // 以下、カスタムパラメータ（最大27個までにすること）
    clinic_id: clinic.id,
  }
}

export const useAnalytics = {
  clinic: {
    index: {
      viewList: (clinics: ClinicListElement[]) => {
        // GA4の推奨イベント
        log('view_item_list', {
          items: clinics.map(formatClinicForInList),
        })
      },
      viewSimilarClinics: (clinics: ClinicListElement[]) => {
        // カスタムイベント
        log('view_additional_clinic_near_item_list', {
          items: clinics.map(formatClinicForInList),
        })
      },
      clickTab: (tabName: ClinicOrderKey) => {
        // カスタムイベント
        log('click_tab_in_clinic_index', {
          tab_name: tabName,
        })
      },
      selectClinic: (clinic: ClinicListElement, index: number) => {
        // GA4の推奨イベント
        log('select_item', formatClinicForInList(clinic, index))
      },
      impressionClinic: (clinic: ClinicListElement, index: number) => {
        // カスタムイベント
        log(
          'impression_clinic_in_clinic_index',
          formatClinicForInList(clinic, index)
        )
      },
      clickNarrowDownButton: (buttonType: 'area' | 'tr') => {
        // カスタムイベント
        log(`click_narrowdown_${buttonType}_in_clinic_index`)
      },
      clickSearchListTag: (tagName: string) => {
        // カスタムイベント
        log('click_narrowdown_area_item_list_middle', {
          tag_name: tagName,
        })
      },
    },
    show: {
      viewClinic: (clinic: Clinic) => {
        // GA4の推奨イベント
        log('view_item', formatClinic(clinic))
      },
      impressionElement: (clinic: Clinic, itemName: string) => {
        const formattedClinic: any = formatClinic(clinic)
        formattedClinic.impression_item_name = itemName
        // カスタムイベント
        log('impression_element_in_clinic_show', formattedClinic)
      },
      clickOfficialUrlButton: (clinic: Clinic) => {
        // カスタムイベント
        log('click_official_url_button', formatClinic(clinic))
      },
      clickOfficialUrlMenuPlan: (clinic: Clinic, menu: MenuListElement) => {
        // カスタムイベント
        log('click_official_url_button_menuplan', {
          ...formatClinic(clinic),
          ...{
            menu_id: menu.id,
            menu_name: menu.name,
            treatment_ids: menu.treatments.map((treatment) => treatment.id),
          },
        })
      },
      clinicOfficialUrlSiteLink: (clinic: Clinic) => {
        log('click_official_url_button_site_link', formatClinic(clinic))
      },
      saveClinic: (clinic: SavedClinic) => {
        // カスタムイベント
        log('click_save_button_clinic', formatSavedClinic(clinic))
      },
      unsaveClinic: (clinicId: number) => {
        // カスタムイベント
        log('click_unsave_button_clinic', { item_id: clinicId })
      },
    },
  },
  menu: {
    show: {
      saveMenu: (menu: SavedMenu) => {
        // カスタムイベント
        log('click_save_button_menu', formatSavedMenu(menu))
      },
      unsaveMenu: (menuId: number) => {
        // カスタムイベント
        log('click_unsave_button_menu', { item_id: menuId })
      },
    },
    special: {
      clickReservationButtonFooter: (clinic: Clinic, menu: MenuListElement) => {
        log(
          'click_reservation_button_spmenu_footer',
          formatSpecialMenu(clinic, menu)
        )
      },
      clickReservationButtonMenuPlansSection: (
        clinic: Clinic,
        menu: MenuListElement
      ) => {
        log(
          'click_reservation_button_spmenu_1',
          formatSpecialMenu(clinic, menu)
        )
      },
      clickReservationButtonPlansSection: (
        clinic: Clinic,
        menu: MenuListElement
      ) => {
        log(
          'click_reservation_button_spmenu_2',
          formatSpecialMenu(clinic, menu)
        )
      },
    },
  },
  user: {
    session: {
      clickLoginButton: () => {
        // カスタムイベント
        log('click_login_button')
      },
      clickRegisterButton: () => {
        // カスタムイベント
        log('click_register_button')
      },
      clickRegisterButtonPopup: () => {
        // カスタムイベント
        log('click_register_button_popup')
      },
      clickNoregisterButtonPopup: () => {
        // カスタムイベント
        log('click_noregister_button_popup')
      },
    },
  },
  onlineClinic: {
    clickReservationButton: () => {
      // カスタムイベント
      log('click_ocreservation_button_oclp')
    },
  },
}
